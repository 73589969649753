import request from '@/utils/request'


// 查询敏感词信息列表
export function listSensitiveWords(query) {
  return request({
    url: '/platform/sensitive-words/list',
    method: 'get',
    params: query
  })
}

// 查询敏感词信息分页
export function pageSensitiveWords(query) {
  return request({
    url: '/platform/sensitive-words/page',
    method: 'get',
    params: query
  })
}

// 查询敏感词信息详细
export function getSensitiveWords(data) {
  return request({
    url: '/platform/sensitive-words/detail',
    method: 'get',
    params: data
  })
}

// 新增敏感词信息
export function addSensitiveWords(data) {
  return request({
    url: '/platform/sensitive-words/add',
    method: 'post',
    data: data
  })
}

// 修改敏感词信息
export function updateSensitiveWords(data) {
  return request({
    url: '/platform/sensitive-words/edit',
    method: 'post',
    data: data
  })
}

// 删除敏感词信息
export function delSensitiveWords(data) {
  return request({
    url: '/platform/sensitive-words/delete',
    method: 'post',
    data: data
  })
}
